import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styles from "./Bio.module.css"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div itemProp="author" itemType="http://schema.org/Person" itemScope>
      <h3>About the Author</h3>
      <div className={styles.author}>
        <Link to="/about/" itemProp="url" className={styles.authorLink}>
          <div>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author.name}
              className={styles.imageHolder}
              imgStyle={{
                borderRadius: `50%`,
                marginBottom: 0,
              }}
              itemProp="image"
              loading="eager"
              critical
              placeholderStyle={{ visibility: "hidden" }}
            />
            <h4 itemProp="name" className={styles.authorName}>{author.name}</h4>
          </div>
        </Link>
        <p>{author.summary}</p>
      </div>
    </div>
  )
}

export default Bio
